.defaultInput_container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 20px;
	.invalid {
		box-shadow: 0 0 4px $main-errors-color;
		color: $main-errors-color;
	}
	.errorMessage {
		color: $main-errors-color;
	}
	input {
		width: 100%;
		height: 40px;
		border-radius: 7px;
		border: 1px solid #818181;
		padding: 10px;
		background-color: #323335;
		color:$main-color;
	}
	& input:focus {
		border: 1px solid #5b82a2;
	}
}

.file_download {
	display: flex;
	margin-bottom: 10px;
	input[type='file'] {
		display: none;
	}
	span {
		margin-left: 20px;
	}
	.custom-file-upload {
		border: 1px solid #90c1f5d4;
		border-radius: 5px;
		color: #57a8ef;
		display: inline-block;
		padding: 6px 12px;
		cursor: pointer;
	}
}
.input_password {
	&.invalid {
		box-shadow: 0 0 4px $main-errors-color;
		color: $main-errors-color;
		.errorMessage {
			color: $main-errors-color;
		}
	}
	display: flex;
	align-items: center;
	height: 40px;
	margin-bottom: 20px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
	border-radius: 7px;
	background: $main-color;
	border: none;
	i {
		margin-right: 10px;
		cursor: pointer;
	}
	.defaultInput_container {
		height: 100%;
		width: 100%;
		margin-bottom: 0;
		input {
			box-shadow: none;
			margin-bottom: 0;
		}
	}
}

@media (max-width: 768px) {
	.defaultInput_container input {
		width: 100%;
	}
	
}
