.dashboard-container{
    width: 80%;
    padding: 0px 5px;
    margin: 0;
    .general-list{
        height: 80%;
        .breakdown-selector{
            padding: 8px 16px;
            border-radius: 4px;
            margin-bottom:16px;
            background-color: #303030;
            color:white;
        }
    }
}

@media (max-width: 768px) {
  .dashboard-container {
    width: 100%!important;
  }
}