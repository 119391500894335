.main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: $main-color;
}
.loginBtn {
	display: none;
}

.login-container {
  display: flex;
	justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 100%;
  text-align: center;
  background-color: #333333;
  padding: 3% 2%;
  width: 18%;
  z-index: 2;
  &.forgot {
		width: 18%;
		color: #6e6e6e;
		h4, h3 {
			font-size: 20px;
		}
  }
  .width-p-100{
    width: 100%;
  }
  .btn {
    width: 100%;
  }
}
.greeting-container {
  width: 72%;
	display: flex;
	flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.greeting {
  width: 100%;
  text-align: left;
  background: $main-color;
  font-size: 5rem;
  font-weight: 100;
  margin-left: calc(100% / 10);
	margin-top: calc(100% / 12);
	h1 {
		font-size: 4rem;
		font-weight: 100;
	}
	p{
		font-size: 1rem;
	}
  span {
    display: block;
    line-height: 1.2;
    color: #1b1b1b;
    width: 100%;
  }
}

.forget-password {
  font-style: italic;
  color: #6e6e6e;
}

@media (max-width: 768px) {
	.login-main-block{
		flex-direction: column;
		justify-content: center;
		margin-top: calc(100% / 12);
		align-items: center;
	}
  .main-container {
    width: 100%;
    align-items: center;
	}
	.loginBtn {
		display: block;
		width: 50%;
		// align-self: flex-end;
	}
  .login-container {
    justify-content: center;
    padding: 0% 2% 4% 0%;
    display: none;
		position: absolute;
    top: 0;
		&.forgot {
			width: 100%;
			height: 120%;
			display: flex;
			div {
				padding: 4%;
			}
    }
    .width-p-100{
      width: initial;
    }
	}
	.content_control {
		display: flex;
    flex-wrap: wrap;
    align-items: center;
		justify-content: center;
		height: 80%;
	}
	.greeting-container {
		width: 100%;
		height: 100%;
	}
  .greeting {
    font-size: 3rem;
    text-align: center;
    margin-left: auto;
		margin-bottom: 1%;
		display: flex;
		// align-items: center;
		justify-content: center;
		flex-direction: column;
		text-align: left;
    padding: 0 20px;
		h1 {
			font-size: 2rem;
		}
  }
}

