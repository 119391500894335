.modal-window {
  min-width: 400px;
  min-height: 200px;
  background-color: #f1f1f1;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__icons {
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__title {
    flex: 1;
  }
  &__describtion {
		flex: 3;
		text-align: center;
  }
  &__button-group {
    display: flex;
    width: 100%;
    flex: 1;
    justify-content: space-evenly;
    .mbtn {
      width: 150px;
    }
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000080;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
