#overlay {
	width: 100%;
	height: 100%;
	position: fixed;
	background: rgba(0, 0, 0, 0.33);
	margin: 0 auto;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
	.clientOverlay,
	.issueOverlay,
	.deleteOverlay {
		background: $main-color;
		width: 50%;
		max-height: 95%;
		border-radius: 10px;
		display: flex;
		flex-direction: column;
		text-align: center;
		padding: 10px;
		.feedlyBoard {
			margin-bottom: 20px;
		}
		form {
			position: relative;
			height: 100%;
		}
		.switch_feedly {
			display: flex;
			label {
				margin-left: 10px;
			}
		}
		.createClientOverlay_form__btn-group,
		.issueOverlay_form__btn-group,
		.deleteOverlay_form__btn-group {
			display: flex;
			justify-content: space-between;
			button {
				width: 47%;
				height: 50px;
			}
		}
		.createClientOverlay_form_news-feed_checkbox{
			display: flex;
			width: 100%;
		}
		.issueOverlay_select {
			margin: 15px;
		}
		.react-select__menu {
			//position: relative
		}
		.issueOverlay_form__btn-group {
			position: static;
			width: 100%;
			bottom: 0;
			margin-bottom: 20px;
		}
		.createClientOverlay_form {
			margin: 20px 10px;
		}
		.text_delete_overlay {
			margin: 25px;
		}
		.deleteOverlay_trashImage {
			font-size: 5em;
			color: #5c5d5d;
		}
	}
	.issueOverlay {
		overflow-y: scroll;
		min-height: 45%;
	}
	.keyWord_container {
		border-radius: 10px;
		margin: 5px 0 20px 0;
		padding: 10px;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
		.keyWord_container__button {
			margin-top: 10px;
			display: flex;
			justify-content: flex-end;
		}
	}
	.overflow {
		overflow: hidden;
	}
	.keyWords {
		margin: 10px;
		display: flex;
		justify-content: flex-start;
		flex-wrap: wrap;
		.card-controle {
			margin-right: 10px;
		}
	}
	.closeOverlay_btn {
		background: none;
		border: 0;
		box-sizing: border-box;
		color: transparent;
		cursor: pointer;
		line-height: 60px;
		margin: 100px 0 0;
		outline: none;
		overflow: hidden;
		padding: 0;
		text-transform: uppercase;
		transition: all 0.2s ease-in;
		width: 60px;
		position: absolute;
		top: -7%;
		right: 3%;
		font-size: 10px;
	}

	.closeOverlay_btn::before,
	.closeOverlay_btn::after {
		background-color: $main-color;
		content: '';
		display: block;
		height: 1px;
		left: 0;
		position: absolute;
		transform-origin: center left;
		transition: all 0.2s ease-in;
		width: 100px;
		z-index: -1;
	}

	.closeOverlay_btn::before {
		top: 0;
		transform: rotate(45deg);
	}

	.closeOverlay_btn::after {
		bottom: 0;
		transform: rotate(-45deg);
	}

	.closeOverlay_btn:hover {
		color: black;
	}

	.closeOverlay_btn:hover::before,
	.closeOverlay_btn:hover::after {
		height: 30px;
		transform: rotate(0deg);
	}
}
