aside {
  position: sticky;
  background-color: #303030;
}
.sideBar_block {
  width: 100%;
  // padding: 4rem 1rem 1rem 1rem;
  padding: 1rem;
  font-family: $font-family-roboto;
  ul {
		// margin-bottom: 50px;
		padding-left: 0; 
    li {
      margin-bottom: 15px;
	  display: table;
	  color: $main-color;
	  a {
		  color: $main-color;
	  }
    }
  }
  .btn-group {
	.btn-sm {
		background-color: $admin-btn-color;
		color: $main-color;
	}
	.btn-sm:hover {
		background-color: $admin-btn-hover-color;
	}
  }
  .filter_item {
    cursor: pointer;
    position: relative;
    color: #f6f6f6;
    padding-left: 5px;
    &.all {
      font-style: italic;
    }
  }
  .active_filter {
    // color: #6c6c6c;
    color: #dedede;
  }

  .filter_item::before {
    content: " ";
    line-height: 1;
    text-align: center;
    display: block;
    width: 18px;
    height: 18px;
    animation: a 0.2s;
    position: absolute;
    top: 2px;
    left: -20px;
    border: 1px solid white;
    border-radius: 3px;
    color: #303030;
  }

  .active_filter::before {
    content: "\2714";
    font-style: normal;
    line-height: 1;
    text-align: center;
    display: block;
    width: 18px;
    height: 18px;
    animation: a 0.2s;
    position: absolute;
    top: 2px;
    left: -20px;
    border: 1px solid #cdcdcd;
    border-radius: 3px;
    color: #303030;
    background-color: $main-color;
  }

  @keyframes a {
    0% {
      width: 0;
      height: 0;
    }
    100% {
      width: 15px;
      height: 10px;
    }
  }
  .active_tab::after,
  .crawlerStatus::after {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    animation: anim 0.8s;
  }
  .active_tab::after {
    background: #17a2b8;
  }
  .completed,
  .failed {
    span {
      margin-right: 10px;
    }
  }
  .completed::after {
    background: green;
  }
  .failed::after {
    background: $main-errors-color;
  }
  @keyframes anim {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
}
.affairs-btn {
  display: flex;
  // background-color: transparent;
  user-select: none;
  // color: #f57474;
  color: #ffffff;
  border: none;
  padding: 0;
  margin-bottom: 10px;
  font: inherit;
  cursor: pointer;
  font-size: 0.5rem;
  transition: all 0.5s;
  transition: all 500ms;
  width: fit-content;
  text-align: left;
  // background-color: #efefef;
  background-color: transparent;
  /* display: block; */
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  span {
    font-size: 14px;
    align-items: center;
    display: flex;
  }
  &:hover, &.checked {
    &.delete{
      background-color: #ec5252;
    }
    &.excel{
      background-color: #1D6F42;
    }
  }
}
.deleted-affairs-btn:focus {
  outline: none;
}
