.drop-down {
  &__header {
    margin-bottom: 16px;
    color: #ffffff;
    // margin-bottom: 0;
  }
  &__title {
    display: flex;
	  overflow: hidden;
    cursor: pointer;
    font-size: 1rem; 
    transition: all 0.5s;
    padding: 5px;
    border-radius: 5px;
    background-color: transparent;
    color: #ffffff;
    align-items: center;
    margin-bottom: 10px;
    &.-checked {
      background-color: #858585;
    }
  }
  &__title:hover {
    background-color: #858585;
  }
  &__container {
    max-height: 0;
    overflow: hidden;
    transition: all 1s;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &.-checked{
      // max-height: calc(100vh - 130px);
      max-height: calc(100vh - 180px);
      overflow-y: auto;
    }
    .children {
      margin-left: 10px;
    }
  }
  &__container::-webkit-scrollbar {
    display: none;
  }
}

