.card-controle {
	max-width: 18rem;
	.card-header {
		display: flex;
		justify-content: space-between;
	}
	.card-body {
		padding: 10px;
		button {
			margin: 0 5px 5px 0;
		}
	}
}
.issues, .content {
	.card-header {
		display: flex;
		justify-content: space-between;
		.button-wrapper{
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex: 1;
		}
		.card-permissionLevel{
			display: inline-block;
			text-align: center;
			vertical-align: middle;
			background-color: transparent;
			border: 1px solid transparent;
			padding: 0.375rem 0.75rem;
			font-size: 1rem;
			line-height: 1.5;
			border-radius: 0.25rem;
			min-width: 93px;
		}
		.btn-group {
			display: flex;
			align-items: center;
			justify-content: center;
			i {
				cursor: pointer;
			}
		}
	}
	.card-body {
		display: flex;
		flex-wrap: wrap;
		.card-controle {
			margin-right: 10px;
		}
		div {
			margin: 2px;
		}
		button {
			margin: 2px;
		}
	}
}

@media (max-width: 768px) {
	.issues, .content {
		.card-header {
			flex-direction: column;
			justify-content: flex-start;
			.button-wrapper{
				justify-content: center;
				align-items: center;
				.btn-link{
					display: flex;
					align-items: center;
					justify-content: center;
				}
			
			}
		}
	}
  }