//MARGIN AND PADDING
$spaceamounts: (5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
	.m-#{$space} {
		margin: #{$space}px !important;
	}
	.p-#{$space} {
		padding: #{$space}px !important;
	}
	@each $side in $sides {
		.m-#{str-slice($side, 0, 1)}-#{$space} {
			margin-#{$side}: #{$space}px !important;
		}
		.p-#{str-slice($side, 0, 1)}-#{$space} {
			padding-#{$side}: #{$space}px !important;
		}
	}
}

//MIN WIDTH
$widthlist: (100, 200, 250, 300, 400, 500, 600, 700, 800);

@each $width in $widthlist {
	.min-w-#{$width} {
		min-width: #{$width}px !important;
	}
	.w-#{$width} {
		width: #{$width}px !important;
	}
	.max-w-#{$width} {
		max-width: #{$width}px !important;
	}
}
