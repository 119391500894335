.defaultSelect_container {
	text-align: left;
	.invalid {
		.css-bg1rzq-control {
			border-color: $main-errors-color;
			.css-16pqwjk-indicatorContainer {
				color: $main-errors-color;
			}
		}
	}
	.errorMessage {
		color: $main-errors-color;
	}
}
