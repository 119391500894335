.docInfo_form {
	padding: 20px;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
	border-radius: 10px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	background: $main-color;
	text-align: center;
	width: 70%;
	.invalid {
		flex-direction: column;
		text-align: left;
		.errorOnField {
			color: $main-errors-color;
			margin: 0;
		}
		.custom-file-upload {
			border: 1px solid $main-errors-color;
			width: 13%;
			color: $main-errors-color;
		}
	}
	.defaultSelect_container {
		margin-bottom: 20px;
		.defaultSelect {
			margin: 0;
		}
	}
	.documentInfo_form__btn-group {
		width: 100%;
		display: flex;
		justify-content: space-between;
		button {
			width: 47%;
			height: 50px;
		}
	}
	h5 {
		margin-bottom: 15px;
	}
	.css-1pcexqc-container {
		margin-bottom: 20px;
	}
	.form_controls {
		display: flex;
		justify-content: space-between;
		margin-bottom: 15px;
		textarea {
			width: 45%;
			border-radius: 10px;
			border: none;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
			padding: 5px;
		}
		.calendar {
			border-radius: 10px;
			border: none;
			box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
			width: 45%;
			align-self: center;
			h6 {
				margin-top: 10px;
			}
			.react-calendar {
				border: none;
				width: 100%;
				height: 100%;
				background: #0a0a0a1a;
			}
		}
	}
}
@media (max-width: 668px) {
	.docInfo_form {
		width: 100%;
	}	
}
