.agency_cleintInfo__info {
	display: flex;
	flex-direction: column;
	div {
		display: flex;
		flex-direction: row;
		p {
			margin-right: 10px;
		}
	}
}
