.show {
	display: block;
}
.hide {
	display: none;
}
.loader {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	margin: 0;
	.logout{
		position: fixed;
    	padding: 20px;
    	text-align: center;
		color: white;
		background-color: rgba(0, 0, 0, 0.33);
		border-radius: 20px 0px 0px 0px;
		right: 0;
		bottom: 0;
		cursor: pointer;
	}
}
.loader .l_main {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 172px;
	height: 128px;
	margin: 0;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
@media (max-width: 550px) {
	.loader {
		-webkit-transform: scale(0.75);
		transform: scale(0.75);
	}
}
@media (max-width: 440px) {
	.loader {
		-webkit-transform: scale(0.5);
		transform: scale(0.5);
	}
}
.l_square {
	position: relative;
}
.l_square:nth-child(1) {
	margin-left: 0px;
}
.l_square:nth-child(2) {
	margin-left: 44px;
}
.l_square:nth-child(3) {
	margin-left: 88px;
}
.l_square:nth-child(4) {
	margin-left: 132px;
}
.l_square span {
	position: absolute;
	top: 0px;
	left: 20px;
	height: 36px;
	width: 36px;
	border-radius: 2px;
	background-color: $main-color;
}
.l_square span:nth-child(1) {
	top: 0px;
}
.l_square span:nth-child(2) {
	top: 44px;
}
.l_square span:nth-child(3) {
	top: 88px;
}
.l_square:nth-child(1) span {
	-webkit-animation: animsquare1 2s infinite ease-in;
	animation: animsquare1 2s infinite ease-in;
}
.l_square:nth-child(2) span {
	-webkit-animation: animsquare2 2s infinite ease-in;
	animation: animsquare2 2s infinite ease-in;
}
.l_square:nth-child(3) span {
	-webkit-animation: animsquare3 2s infinite ease-in;
	animation: animsquare3 2s infinite ease-in;
}
.l_square:nth-child(4) span {
	-webkit-animation: animsquare4 2s infinite ease-in;
	animation: animsquare4 2s infinite ease-in;
}
.l_square span:nth-child(1) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
}
.l_square span:nth-child(2) {
	-webkit-animation-delay: 0.15s;
	animation-delay: 0.15s;
}
.l_square span:nth-child(3) {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}
@-webkit-keyframes animsquare1 {
	0%,
	5%,
	95%,
	100% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	30%,
	70% {
		-webkit-transform: translate(-40px, 0px) rotate(-90deg);
		transform: translate(-40px, 0px) rotate(-90deg);
	}
}
@keyframes animsquare1 {
	0%,
	5%,
	95%,
	100% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	30%,
	70% {
		-webkit-transform: translate(-40px, 0px) rotate(-90deg);
		transform: translate(-40px, 0px) rotate(-90deg);
	}
}
@-webkit-keyframes animsquare2 {
	0%,
	10%,
	90%,
	100% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	35%,
	65% {
		-webkit-transform: translate(-40px, 0px) rotate(-90deg);
		transform: translate(-40px, 0px) rotate(-90deg);
	}
}
@keyframes animsquare2 {
	0%,
	10%,
	90%,
	100% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	35%,
	65% {
		-webkit-transform: translate(-40px, 0px) rotate(-90deg);
		transform: translate(-40px, 0px) rotate(-90deg);
	}
}
@-webkit-keyframes animsquare3 {
	0%,
	15%,
	85%,
	100% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	40%,
	60% {
		-webkit-transform: translate(-40px, 0px) rotate(-90deg);
		transform: translate(-40px, 0px) rotate(-90deg);
	}
}
@keyframes animsquare3 {
	0%,
	15%,
	85%,
	100% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	40%,
	60% {
		-webkit-transform: translate(-40px, 0px) rotate(-90deg);
		transform: translate(-40px, 0px) rotate(-90deg);
	}
}
@-webkit-keyframes animsquare4 {
	0%,
	20%,
	80%,
	100% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	45%,
	55% {
		-webkit-transform: translate(-40px, 0px) rotate(-90deg);
		transform: translate(-40px, 0px) rotate(-90deg);
	}
}
@keyframes animsquare4 {
	0%,
	20%,
	80%,
	100% {
		-webkit-transform: translate(0px, 0px) rotate(0deg);
		transform: translate(0px, 0px) rotate(0deg);
	}
	45%,
	55% {
		-webkit-transform: translate(-40px, 0px) rotate(-90deg);
		transform: translate(-40px, 0px) rotate(-90deg);
	}
}
