.reset_button_default_styles {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.side-affair-card {
  :first-child {
    &.affairDetails {
      cursor: default;
    }
  }
}

.side-affair-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  background-color: rgb(225, 225, 231);

  .affair-comment-divider {
    text-align: center;
    line-height: 0.8;
    // cursor: grabbing;
    cursor: default;
    padding-bottom: 5px;
  }

  .affair-comment-section {
    width: 100%;
    padding: 1rem 2rem;
    background-color: white;

    .ql-editor {
      min-height: 100px;
    }

    textarea {
      width: 100%;
      padding: 1rem;
      font-size: 14px;
      border-radius: 2px;
      border: 1px solid #ccc;
      margin-top: 0.5rem;
    }

    p {
      padding: 1rem 0.5rem;
      font-size: 14px;
    }

    .affair-comment-action {
      text-align: right;

      .affair-comment-send {
        margin-top: 10px;
      }
    }

    .affair-comment-list {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;

      .affair-comment {
        margin-bottom: 8px;

        img {
          width: 100%;
        }

        p>br {
          display: none;
        }

        .affair-comment-list-label {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          font-weight: bold;
          color: #104d66;
        }
      }
    }

    .affair-comment-input-wrapper {
      width: 100%;
      // display: flex;
      // flex-direction: row;
      // justify-content: flex-start;
      align-items: center;

      .affair-comment-input {
        padding: 4px 6px;
        font-weight: bold;
        border: 2px solid black;
        border-radius: 4px;
        flex: 1
      }

      .affair-comment-send {
        padding: 2px 5px;
        margin-left: 4px;
        border-radius: 4px;
        color: white;
        background-color: #104d66;
        border: 2px solid #104d66;
        font-size: 12px;
      }
    }

    .selected-files-wrapper {
      display: flex;

      .btn-remove {
        text-align: right;
        flex-grow: 1;

        a {
          color: red;
        }
      }
    }
  }
}

.argusRating {
  width: 40px;
}

.argusTableRow {
  width: 25%;
  justify-content: center;
  align-items: center;
  padding: 10px 0px 10px 0px;
  text-align: center;
}

.argusTable {
  width: 100%;
}

.affair_item {
  background: #f2f2f2;
  // border-bottom-right-radius: 10px;
  // border-bottom-left-radius: 10px;
  padding: 15px 15px 10px 15px;
  margin-bottom: 20px;
  z-index: 0;

  cursor: pointer;

  &.checked {
    background-color: #bacad1;
  }

  .capitalize {
    text-transform: capitalize;
  }

  .content_affair {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
    }

    .affair {
      border-radius: 10px;
      background: $main-color;
      padding: 20px;
      margin-bottom: 30px;
    }

    .affair_description {
      width: 100%;
    }
  }

  .list-group-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.3rem 1.2rem;
    border: none;

    a {
      color: $polits-title-color;
      text-align: right;
      margin-left: 5px;
    }

    p {
      margin-left: 5px;
      margin-bottom: 0;
      font-weight: 300;
    }

    button {
      height: 27px;
    }

    div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;

      button {
        margin-left: 3px;
      }
    }
  }

  .active {
    padding: 0.75rem 1.25rem;
    border: none;
    background: $main-color;
    color: black;
  }

  .docInfo_block {
    background: $main-color;
    padding: 15px;
    border-radius: 10px;
    margin: 10px 0;
  }
}

.affair_item_checked {
  background: #ffffff;
}

.affair_block {
  font-family: $font-family-roboto;
  font-size: 14px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  // overflow: hidden;
  background: $polits-title-color;
  display: flex;
  flex-direction: row;
  -webkit-line-clamp: 2;
  /* autoprefixer        : ignore next */
  -webkit-box-orient: vertical;


  h3,
  h4 {
    margin: 10px;
    font-size: 16px;
    font-weight: 700;
  }

  h5 {
    margin: 10px;
    font-size: 1em;
    letter-spacing: 0.01em;
    line-height: 1.25em;
    overflow: hidden;
    height: 2.5em;
    color: $main-color;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* autoprefixer   : ignore next */
    -webkit-box-orient: vertical;
    font-weight: 700;
  }

  a {
    flex: 1;
  }

  .watcher_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 8px;
    color: white;

    .watcher_icon_wrapper {
      position: relative;

      i {
        cursor: pointer;
        font-size: 16px;
      }

      .watcher_dropdown_container {
        position: absolute;
        right: 0;
        background-color: white;
        color: #303030;
        border: 1px solid #303030;
        padding: 16px 24px;
        z-index: 1;
        max-height: 50vh;
        overflow-y: scroll;

        .watcher_email_container {
          display: flex;
          width: 100%;
          align-items: center;

          .watcher_dropdown_email {
            margin-left: 4px;
          }
        }

        .close-button {
          position: absolute;
          top: 4px;
          right: 8px;
          font-size: larger;
          cursor: pointer;
        }
      }
    }

  }
}

.affairDetails {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  flex: 1;
  margin-bottom: 0px;

  h3 {
    color: $main-color;
    margin: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    /* autoprefixer   : ignore next */
    -webkit-box-orient: vertical;
  }

  .affairDetails_description {
    display: flex;
    flex-direction: column;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1rem;

      li {
        margin: 0;
        padding: 0.75rem;
        width: 50%;
        box-sizing: content-box;

        &:nth-child(even) {
          // white-space: nowrap;
          width: auto;

          p.bold {
            width: 60%;
          }
        }

        p:last-child {
          width: 50%;
          margin-left: 0;
        }

        p.bold {
          margin-left: 0;
          width: 50%;
        }

        .update-date {
          //width: 50%;
          padding-left: 0.75rem;
          margin-left: 0;
        }

        // @media (max-width: 1080px) {
        // 	width: 100%;
        // }
      }
    }

    .polit-keywords {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0.5rem 0.8rem;

      p {
        font-weight: 100;
      }

      span.btn-sm {
        font-weight: 400;
        padding: 0.15rem 0.5rem;
      }
    }
  }

  .affairDetails_docs {
    width: 100%;
    display: grid;
    grid-column: 3;

    .affair_description {
      padding: 10px;
      border-radius: 10px;

      .active {
        background: #ffffff;
      }

      .list-group-item {
        p {
          margin-left: 5px;
        }
      }
    }
  }
}

.media-affair,
.feedly-doc-affair {
  .affair_block {
    background: $feedly-media-title-color;
  }

  .checked {
    background-color: #b2d1c3;
  }
}

.icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 100;

  .btn-circle {
    width: 10px;
    height: 20px;
    border-radius: 50%;
  }
}

.card-icon {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.priority_block {
  display: inline-flex;

  .check-list {
    display: none;
  }

  .feed-changer {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border: 1px dashed #303030;
    border-radius: 50%;
    margin-right: 5px;
    cursor: pointer;

    .switcher {
      background-color: #303030;
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }

    &.high {
      border: none;
      background-color: red;

      .switcher {
        display: none;
      }
    }

    &.medium {
      border: none;
      background-color: yellow;
      cursor: pointer;

      .switcher {
        display: none;
      }
    }

    &.low {
      border: none;
      background-color: green;
      cursor: pointer;

      .switcher {
        display: none;
      }
    }

    &.none {
      border: none;
      background-color: #303030;
      cursor: pointer;

      .switcher {
        display: none;
      }
    }
  }
}

.priority_block:hover {
  .check-list {
    display: flex;
    visibility: visible;
    opacity: 1;
    margin: 0;
    margin-right: 5px;
    justify-content: center;
    align-items: center;

    li {
      display: flex;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 5px;
      cursor: pointer;
      border: none;

      .switcher {
        display: none;
      }
    }

    .bold {
      font-weight: bold;
    }

    .high {
      background-color: red;
    }

    .medium {
      background-color: yellow;
    }

    .low {
      background-color: green;
    }

    .none {
      background-color: #303030;
    }
  }
}

.custom-keyword {
  background-color: #303030;
  color: #ffffff;
  padding: 0.15rem 0.5rem;
  margin: 0.5rem 0.75rem 0 0;
  border: none;
  border-radius: 6px;
  font-family: $font-family-roboto;
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
}

.docs {
  width: 100%;
  display: flex;
  flex-direction: column;

  .block-title {
    width: 100%;
    font-weight: 700;
    padding: 1rem 0rem 1rem 0.75rem;
    font-size: 1em;
    font-family: $font-family-roboto;
  }

  .doc-info {
    width: 100%;
    border-top: 1px solid #edeef0;

    th {
      height: 3rem;
      font-weight: 700;
      font-family: $font-family-roboto;
      padding-left: 0.75rem;
    }

    th:first-child {
      width: 25%;
    }

    td {
      vertical-align: top;
      padding: 0.75rem;
      font-weight: 300;
      font-family: $font-family-roboto;

      span {
        cursor: pointer;
      }
    }

    tbody tr:nth-child(odd) {
      background-color: #f2f2f2;
    }

    .keyword-block {
      display: flex;
      flex-flow: row wrap;
      padding: 0;
      margin: 0.5rem 0.25rem 0.25rem 0;
    }
  }
}

.block-titles {
  width: 100%;
  font-weight: 700;
  padding: 1rem 0rem 1rem 0.75rem;
  font-size: 1em;
  font-family: $font-family-roboto;
}