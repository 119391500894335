html,
body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffffff;
}

main {
	padding-left: 20px;
	width: 76%;
}

.additional-select-container{
  display: block;
  align-items: center;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 7px;
  background: #323335;
  border: none;
  .additional-select {
    width: 100%;
    height: 40px;
    border-radius: 7px;
    border: 1px solid #818181;
    padding: 10px;
    background-color: #323335;
    color: #ffffff;
  }
}


.cleintInfoContainer {
	width: 70%;	
  .actionDisplay{
    border-radius: 10px;
    background: $main-color;
    overflow: hidden;
    .NavigationWrapper{
      width: 100%;
      display: flex;
      .navigationOption{
        flex: 0.5;
        padding: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        background-color: #21252959;
        cursor: pointer;
        font-weight: bold;
      }
      & .active{
        background-color: #212529;
        cursor: default;
      }
    }
    .content{
      padding: 20px;
      display: block;
    }
  }
}

#root {
  height: 100%;
  .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    cursor: pointer;
  }
}
.agency.background {
  height: 100%;
}
body.no-scroll {
  overflow: hidden;
}
header {
	height: 10%;
}
header,
.mobile_button {
  display: flex;
  align-items: center;
  background: $main-color;
  // height: 80px;
  .header_container {
    display: flex;
    align-items: center;
    margin: 20px;
    .sing_out {
      height: 100%;
    }
    // flex-direction: row-reverse;
  }
  .logoBox {
    display: grid;
    width: 15%;
  }
  .logo {
    max-width: 100%;
  }
  .button_menu {
    display: none;
    font-size: 36px;
  }
  .icon_exit {
    font-size: 24px;
    margin-right: 20px;
  }
}
.headerActions {
  flex-grow: 1;
  text-align: right;
  margin-bottom: 0;
  li {
    display: inline-block;
    padding: 0 12px;
    text-align: center;
  }
}
.footerActions {
  background-color: #ffffff;
  border-top: 1px solid #dddddd;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  .col {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.headerActions, .footerActions {
  a {
    color: #666666!important;
    i { font-size: 22px; }
    div { font-size: 12px; font-weight: 400; }
    &:hover, &.active {
      color: #191919!important;
      cursor: pointer;
    }
  }
}
.agency.container {
  aside {
		display: flex;
    flex-direction: column;
  }
  .sing_out {
		color: $main-color;
		margin: 1rem 1rem;
  }
}
.mobile_button {
  justify-content: flex-end;
  position: fixed;
  top: 0;
  height: 10vh;
  z-index: 3;
  width: 100vw;
}
li {
  list-style-type: none;
}
a,
a:hover {
  color: $polits-title-color;
  text-decoration: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input :active,
:hover,
:focus {
  outline: 0;
  outline-offset: 0;
}
.content {
  display: flex;
	flex-direction: row;
	height: 100%;
  &.user-view {
    display: block;
    width: 85%;
    margin: auto;
  }
  .issues-list {
    position: relative;
		width: 18%;
    z-index: 2;
    .client-page.sing_out {
      position: absolute;
      top: 15px;
      color: #ffffff;
    }
    aside {
			height: 100%;
			.drop-down {
				.drop-down__container ul {
					margin-left: 48px
				}
			}
    }
  }
  .affair-list {
		width: 34%;
		padding-left: 15px;
  }
  .affair-list::-webkit-scrollbar {
    width: 8px;               /* width of the entire scrollbar */
  }
  
  .affair-list::-webkit-scrollbar-thumb {
    background-color: #303030;    /* color of the scroll thumb */
    border-radius: 20px;       /* roundness of the scroll thumb */
    /* border: 3px solid orange;  creates padding around scroll thumb */
  }
  .side-affair-card {
    width: 50%;
    overflow-y: auto;
  }
  .cleintInfo,
  .issues,
  .affair,
  .linkPdf {
    border-radius: 10px;
    background: $main-color;
    padding: 20px;
    margin: 30px 0;
  }
  .cleintInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .agency_cleintInfo__btn-bar{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .info-buttons{
        width: fit-content;
        height: fit-content;
        margin-left: 8px;
      }
    }
  }
  .back_to_top_button {
    display: none;
    position: fixed;
    bottom: 3vw;
    right: 5vw;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #5c5d5d;
    color: $main-color;
    border-width: 0;
    font-size: 2em;
    transform: rotate(180deg);
    outline: 0;
  }
}
.bold {
  font-weight: 500;
}

// STYLES FIXES
a {
  overflow-x: hidden;
}
.btn-sm {
  text-transform: lowercase;
  background-color: #303030;
  color: #ffffff;
  margin: 0.5rem 0.75rem 0 0;
  cursor: pointer;
  border: none;
  border-radius: 6px;
  font-size: 16px;
}
.btn-sm:hover {
  background-color: #5c5d5d;
}
.fas {
  margin: 2px;
  line-height: 20px;
}
.log-in_btn {
  width: 10rem;
}

@media (max-width: 1366px) {
  .content {
    .back_to_top_button {
      right: 2.5vw;
      bottom: 1vw;
    }
  }
}
@media (max-width: 1280px) {
  .content {
    .back_to_top_button {
      right: 1.2vw;
      width: 40px;
      height: 40px;
      font-size: 1.8em;
      line-height: 30px;
    }
  }
}
@media (max-width: 1224px) {
  .content {
    .back_to_top_button {
      right: 0.5vw;
    }
  }
}
@media (max-width: 1200px) {
  .content {
    .back_to_top_button {
      right: 2vw;
      width: 50px;
      height: 50px;
      font-size: 2em;
    }
  }
}
@media (max-width: 1080px) {
  .content {
    .back_to_top_button {
      right: 0.5vw;
      width: 40px;
      height: 40px;
      font-size: 1.8em;
      line-height: 30px;
    }
  }
}
@media (max-width: 1024px) {
  .content {
    .back_to_top_button {
      right: 0.2vw;
    }
  }
}
@media (max-width: 990px) {
  .content {
    .back_to_top_button {
      right: 2.5vw;
      width: 50px;
      height: 50px;
      font-size: 2em;
    }
  }
}

@media (max-width: 840px) {
  header {
    .logo {
      width: 280px;
      justify-self: end;
    }
    .logoBox {
      width: 50%;
    }
    .header_container {
      justify-content: space-between;
    }
    .button_menu {
      display: block;
    }
  }
  .content {
    .back_to_top_button {
      right: 0.5vw;
      width: 40px;
      height: 40px;
      font-size: 1.8em;
      line-height: 30px;
    }
  }
  .affairDetails {
    .affairDetails_description {
      ul {
        li.list-group-item {
          p.bold {
            width: 65%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  header {
    .logo {
      justify-self: end;
    }
    .header_container {
      justify-content: space-between;
    }
    .button_menu {
      display: block;
    }
	}
	.agency.container {
		aside {
			width: 100%;
		}
	}
	main {
		width: 95%;
	}
	.cleintInfoContainer {
		width: 100%;
    .cleintInfo {
      flex-direction: column;
      .agency_cleintInfo__btn-bar{
        .info-buttons{
          margin-top: 16px;
          margin-left: 0px;
        }
      }
    }
  
	}

  
  .content {
    .back_to_top_button {
      right: 2vw;
      width: 50px;
      height: 50px;
      font-size: 2em;
    }
    .issues-list {
      width: 75%;
      height: 100vh;
      position: absolute;
      z-index: 2;
      margin: 0;
    }
    .affair-list {
			width: 100vw;
			padding: 5px;
    }
    .side-affair-card {
      position: absolute;
      width: 100%;
      background-color: #fff;
      padding-top: 10vh;
      height: 100vh;
    }
	}
	.side-affair-card {
		.affairDetails {
			.affairDetails_description {
				ul {
					li {
						width: 100%;
					}
					li.list-group-item {
						p.bold {
							width: 50%;
						}
					}
				}
			}
			.docs .doc-info td span {
				padding-right: 0;
				word-break: break-word;
			}
		}
	}
	.login-container {
		&.open_login {
			display: flex;
			width: 100%;
			animation-duration: 0.5s;
			animation-name: slideinopen;
		}
		&.close_login {
			animation-duration: 0.5s;
			animation-name: slideinclose;
		}
		@keyframes slideinopen {
			from {
				width: 0%
			}
		
			to {
				width: 100%
			}
		}
		@keyframes slideinclose {
			from {
				width: 100%;
			}
		
			to {
				display: none;
				width: 0%
			}
		}
	}
}
@media (max-width: 668px) {
  .content {
    .back_to_top_button {
      left: 18vw;
      right: auto;
      width: 50px;
      height: 50px;
      font-size: 2em;
    }
  }
  .affairDetails {
    .affairDetails_description {
      ul {
        li {
          &:nth-child(2) {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 425px) {
  header {
    .logoBox {
      width: 70%;
    }
  }
  .content_affair .affair_description {
    .list-group-item {
      p {
        width: 100%;
        word-break: break-word;
      }
    }
  }
}

@media (max-width: 375px) {
  .affairDetails {
    .affairDetails_description {
      ul {
        li.list-group-item {
          &:nth-child(2) {
            width: 100%;
          }
          .update-date {
            position: absolute;
            bottom: 12px;
            right: 0;
          }
          p.bold {
            width: 60%;
          }
        }
      }
    }
  }
  .greeting-container {
    span {
      font-size: 36px;
    }
  }
}

@media (max-width: 350px) {
  .affairDetails {
    .affairDetails_description {
      ul {
        li.list-group-item {
          p {
            &:last-child {
              width: 35%;
            }
          }
        }
      }
    }
  }
}
