.container {
	margin: 0;
	padding: 0;
	max-width: 100%;
	height: 90%;
	.issues {
		.issues_keywords {
			display: flex;
			flex-wrap: wrap;
			div.card-controle {
				margin-right: 2px;
			}
		}
	}
}
.dates input {
	font-weight: 300;
	color: #212529;
	font-size: 1rem;
}
.general-list {
	position: relative;
	height: 90%;
	.multi_filter_block {
		position: absolute;
		background-color: #f2f2f2;
		z-index: 1;
		display: none;
		padding: 1em;
		border-radius: 10px;
		height: 100%;
		overflow-y: auto;
		scroll-behavior: smooth;
		width: 100%;
		.timeperiod-filter {
			display: inline-flex;
			flex-direction: column;
			width: 100%;
			.dates {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				p {
					margin: 0 0.25em;
					font-weight: 300;
				}
				input {
					border-radius: 6px;
					padding: 0.25em 0.5em;
					margin: 0 0.25em;
					width: 100%;
				}
				input:focus {
					border: 2px solid #326cae;
				}
				.start-date, .end-date {
					display: flex;
					flex-direction: row;
					align-items: center;
					i {
						margin-left: 0.5em;
					}
				}
			}
		}
		.politsources-filter, .mediasources-filter {
			display: flex;
			flex-direction: column;
			width: 100%;
			.kn-column__column___3Iwzx {
				width: 50%;
				display: flex;
				input {
					width: 100%;
				}
			}
			.kn-multi_select__wrapper___30BEc {
				.kn-selection_status__selection_status___1FzyX {
					.kn-selection_status__status___1qxE9 {
						color: #666666;
					}
				}
			}
			.block-header {
				display: flex;
				flex-direction: row;
				align-items: center;
				p {
					margin: 0;
				}
				.toggle-button {
					border: none;
					background: none;
					width: auto;
					height: auto;
					align-self: center;
				}
			}
		}
		h5 {
			color: #707070;
			font-weight: 400;
			
		}
		:nth-child(1) {
			border-radius: 4px;
		}
		&.active {
			display: block;
		}
		.timeperiod-filter,.politsources-filter,.mediasources-filter,.filter-buttons {
			margin-bottom: 1em;
    	font-weight: 700;
			font-family: "Roboto", sans-serif;
			.kn-multi_select__wrapper___30BEc, .kn-selection_status__status___1qxE9 {
				font-weight: 300;
				color: #212529!important;
				font-size: 1rem;
			}
		}
	}
	.affair-list {
		overflow-y: scroll;
		width: 100%;
		padding: 0;
		margin: 0;
		height: 100%;
		.issuesIsEmpty {
			padding-left: 0.5rem;
		}
	}
}

.control-board {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 15px 0px;
	input {
		border-radius: 4px;
		box-shadow: 1px solid black;
		padding: 5px;
	}
	.dropdown{
		.high, .medium, .low, .fas {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			text-align: center;
			display: inline-flex;
			align-items: center;
			margin-right: 5px;
		}
		.fas {
			margin-top: 0;
			padding-top: 0;
		}
		.bold{
			font-weight: 700;
		  }
		.high {
			background-color: red;
		}
		.medium {
			background-color: yellow;
		}
		.low {
			background-color: green;
		}
		.dropdown-toggle {
			background: none;
			border: none;
			color: #303030;	
			padding-left: 0;
			.fas {
				color: #0174d0;
			}
			.field-title{
				font-weight: 500;
				margin: 0 5px;
			}
		}	
		.dropdown-menu {
			padding: 0;	
			li div {
				display: inline-flex;
				justify-content: center;
				align-items: center;
				margin-left: 10px;
				cursor: pointer;
				.fas {
					color: #0174d0;
					margin-left: 0;
				}
			}
			li:hover {
				background-color: lightblue;
			}
			.high, .medium, .low, .fas {
				margin-right: 10px;
			}
		}
	}
	.filter-board {
		display: flex;
		justify-content: center;
		align-items: center;
		.form-control:focus {
			width: 16em;
		}
		.fa-filter{
			margin: 0 0.5rem;
			cursor: pointer;
			&.active {
				color: #0074d7;
			}
		}
		
	}	
}

.filter-buttons {
	display: flex;
	justify-content: flex-end;
	.btn-primary, .btn-outline-secondary {
		font-size: 1rem;
		transition: all 0.5s;
		color: #ffffff;
		align-items: center;
		padding: 5px;
	}
	.btn-primary {
		margin-left: 0.5em;
		background-color: #303030;
		border-color: #303030;
	}
	.btn-outline-secondary {
		margin-right: 0.5em;
		background-color: #858585;
		border-color: #858585;
	}
}
